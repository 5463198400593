<script setup lang="ts">
import type { CmsBlockText } from "@shopware/composables";
import { useCmsBlock } from "#imports";
import {getCmsLayoutConfiguration} from "@shopware/helpers";

const props = defineProps<{
  content: CmsBlockText;
  style: any;
  class: any;
}>();

const { getSlotContent } = useCmsBlock(props.content);

const content = getSlotContent("content");

const { cssClasses, layoutStyles } = getCmsLayoutConfiguration(props.content);

</script>
<template>
  <div class="zebra-cms-light-teaser" :class="cssClasses" :styles="layoutStyles">
    <CmsGenericElement :content="content" />
  </div>
</template>